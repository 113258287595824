.notification {
  z-index: 1000000;
}
::-webkit-scrollbar-corner {
  background-color: #F8FAFF;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  transition: all 0.3s;
}
::-webkit-scrollbar-track {
  background-color: #F8FAFF;
  -webkit-border-radius: 18px;
  border-radius: 18px;
}
::-webkit-scrollbar-thumb {
  border-radius: 18px;
  background: #9998b8;
}
// ::-webkit-scrollbar-thumb:window-inactive {
//   background: transparent;
// }

// ::-webkit-scrollbar-track:window-inactive {
//   background: transparent;
// }

a:-webkit-any-link {
  text-decoration: none;
}

@-webkit-keyframes shine {
  to {
    background-position: 200%;
  }
}
@keyframes shine {
  to {
    background-position: 200%;
  }
}
